import React from "react"
import Avatar from "@mui/material/Avatar"
import Button from '@mui/material/Button'
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Stack from "@mui/material/Stack"
import useSparkleAlert from "../../../alert/useSparkleAlert"
import { updatePicture } from "../../../../api/public/user"
import useLoggedUser from "../../../../custom-hooks/useLoggedUser"

export function useUploadProfilePicture() {
  const [show, setShow] = React.useState(false)

  const showUploadProfilePictureDialog = () => {
    setShow(true)
  }

  const closeUploadProfilePictureDialog = () => {
    setShow(false)
  }

  const UploadProfilePictureDialog = () => {
    const fileInputButton = React.useRef(null)

    const [isLoading, setIsLoading] = React.useState(false)

    const [picture, setPicture] = React.useState(null)

    const [imgData, setImgData] = React.useState(null)

    const [imageLink, setImageLink] = React.useState(null)

    const { showSparkleAlert, SparkleAlert } = useSparkleAlert()

    const { getUser, setNewUserPhoto } = useLoggedUser()

    const handleSelectPicture = () => {
      fileInputButton.current.click()
    }

    const onChangePicture = (e) => {
      e.preventDefault()
      if (e.target.files[0]) {
        setPicture(URL.createObjectURL(e.target.files[0]))
        setImageLink(e.target.files[0])
      }
    }

    const clickSubmitPicture = (e) => {
      setIsLoading(true)
      const users = getUser()

      if (imageLink) {
        e.preventDefault()

        updatePicture(users?.userId, users.token, imageLink).then((data) => {
          if (data?.photo) {
            console.log(data)
            setNewUserPhoto({ userPhoto: data?.photo })
            showSparkleAlert("Profile picture updated", "success")
            setIsLoading(false)
          } else {
            showSparkleAlert("Error uploading image", "error")
            setIsLoading(false)
          }
        })
      } else {
        showSparkleAlert("Please select a picture", "error")
        setIsLoading(false)
      }
    }

    return (
      <Dialog fullWidth open={show} onClose={closeUploadProfilePictureDialog}>
        <DialogContent>
          <Stack spacing={2}>
            <div style={{ paddingTop: "1.5em" }}></div>
            <Stack direction={"row"} justifyContent={"center"}>
              <Avatar
                sx={{
                  height: "200px",
                  width: "200px",
                  background: "gray",
                  borderRadius: "50%",
                }}
              >
                {picture && (
                  <img
                    src={picture}
                    alt={"selected profile picture"}
                    style={{
                      height: "200px",
                      width: "200px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Avatar>
            </Stack>
            <SparkleAlert />
            <input
              ref={fileInputButton}
              type="file"
              style={{ display: "none" }}
              onChange={onChangePicture}
            />
            <Button
              disabled={isLoading}
              fullWidth
              variant="contained"
              style={{ fontFamily: "visby" }}
              onClick={handleSelectPicture}
            >
              Choose a picture
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              style={{ fontFamily: "visby" }}
              onClick={clickSubmitPicture}
            >
              {isLoading ? "Loading..." : "Submit"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }

  return {
    showUploadProfilePictureDialog,
    UploadProfilePictureDialog,
  }
}
