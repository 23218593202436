import React, { useState, useEffect } from "react"

import Box from "@material-ui/core/Box"
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import PersonIcon from "@material-ui/icons/Person"
import PhoneIcon from "@material-ui/icons/Phone"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import CameraAltIcon from "@material-ui/icons/CameraAlt"
import EditIcon from "@material-ui/icons/Edit"
import EmailIcon from "@material-ui/icons/Email"
import LinkIcon from "@material-ui/icons/Link"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { theme } from "../../../assets/mui"
import CircularProgress from "@material-ui/core/CircularProgress"
import useWindowDimensions from "../../../custom-hooks/useWindowDimensions"

import ls from '../../../services/securels'

import moment from "moment"
import { Link, navigate } from "gatsby"
import {
  deleteAccount,
  getUserById,
  updatePicture,
} from "../../../api/public/user"
import useLoggedUser from "../../../custom-hooks/useLoggedUser"

import Alert from '@mui/material/Alert'
import AlertTitle from "@mui/material/AlertTitle"
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import { useDeleteAccountDialog } from "./components/useDeleteAccountDialog"
import { LoginState } from "../../globalstates"
import { useUploadProfilePicture } from "./dialogs/useUploadProfilePicture"

const useStyles = makeStyles({
  backSize: {
    fontSize: "1.5em",
    color: "black",
  },
  button: {
    background: "#ffcf10",
    borderRadius: 3,
    color: "black",
    height: 35,
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "#9C9C9C",
      color: "#fff",
    },
  },
  avatar: {
    height: "200px",
    width: "200px",
    background: "gray",
    borderRadius: "50%",
  },
  large: {
    height: "100px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  decoration: {
    textDecoration: "none",
  },
  camera: {
    padding: "10px",
    border: "2px solid white",
    backgroundColor: "#D3D3D3",
    borderRadius: "100%",
  },
  listBackground: {
    backgroundColor: "#F2F7FD",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    borderRadius: "0.2em",
    paddingLeft: "0.2em",
  },
  flex: {
    display: "flex",
    marginLeft: "1em",
    marginBottom: "0.2em",
  },
  title: {
    fontWeight: "bold",
    fontFamily: "visby",
  },
  body: {
    fontFamily: "visby",
    marginLeft: "1em",
  },
  img: {
    lineHeight: "90px",
    objectFit: "fill",
  },
  float: {
    position: "absolute",
    zIndex: 1,
  },
  centerFit: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
    display: "block",
  },
  icon: {
    color: "#448cf6",
    marginLeft: "0.1em",
    marginRight: "0.3em",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "white",
    height: "40%",
  },
  avatarImg: {
    height: "200px",
    width: "200px",
    objectFit: "cover",
  },
})

const UserDetailsPage = () => {
  const classes = useStyles()

  const [userData, setUserData] = useState({
    _id: "",
    name: "",
    phone: "",
    address: "",
    birthdate: "",
    gender: "",
    messenger: "",
    photo: "",
    email: "",
  })
  const [open, setOpen] = useState(false)
  const [openPicture, setOpenPicture] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { height, width } = useWindowDimensions()

  const [isRedirected, setIsRedirected] = useState(false)

  const [isLoggin, setisLoggin] = React.useContext(LoginState)

  const { showUploadProfilePictureDialog, UploadProfilePictureDialog } =
    useUploadProfilePicture()

  const { showDeleteAccountDialog, DeleteAccountDialog } =
    useDeleteAccountDialog()

  const { getUser, setNewUserPhoto } = useLoggedUser()

  const {
    _id,
    name,
    phone,
    address,
    photo,
    gender,
    messenger,
    birthdate,
    email,
  } = userData

  const isMountedRef = React.useRef(false)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  }, [])

  useEffect(() => {
    const user = getUser()

    //

    getUserById(user.userId)
      .then((data) => {
        if (isMountedRef.current) {
          setUserData(data)
          setLoaded(true)
        }
      })
      .catch((error) => { })
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenPicture = () => {
    showUploadProfilePictureDialog()
  }

  const handleChangePicture = (name) => (e) => {
    setUserData({ ...userData, [name]: e.target.value })
  }

  const handleDeleteAccountOnClick = () => {
    showDeleteAccountDialog()
  }

  const onConfirmDeleteAccount = async () => {
    try {

      await deleteAccount({ userId: _id })

      if (window?.ReactNativeWebView?.postMessage) {
        let postMessageData = {
          type: "LOGOUT",
          payload: "",
        }

        window.ReactNativeWebView.postMessage(JSON.stringify(postMessageData))
      }

      ls.removeAll()

      setisLoggin(false)

      navigate("/account")

      return
    } catch (error) {
      throw error
    }
  }

  if (isRedirected) {
    navigate("/user")
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <AppBar elevation={0}>
          <Toolbar>
            <Link to="/food">
              <ArrowBackIcon className={classes.backSize} />
            </Link>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <div>
          <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div>
                <img
                  src={photo}
                  alt="User Profile"
                  className={`${classes.centerFit}`}
                />
              </div>
            </Fade>
          </Dialog>
        </div>
        {loaded ? (
          <>
            <div className={classes.center}>
              <div>
                <UploadProfilePictureDialog />
              </div>
              <div
                style={{
                  position: "relative",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CameraAltIcon
                  fontSize="large"
                  onClick={handleOpenPicture}
                  className={` ${classes.camera} ${classes.float} `}
                />
                <Avatar
                  onClick={handleOpen}
                  sx={{
                    height: "200px",
                    width: "200px",
                    background: "gray",
                    borderRadius: "50%",
                  }}
                >
                  {photo ? (
                    <img
                      src={photo}
                      alt="User profile picture"
                      className={`${classes.avatarImg}`}
                      onChange={handleChangePicture("photo")}
                    />
                  ) : (
                    name?.charAt(0)
                  )}
                </Avatar>
              </div>
            </div>
            <Box pt={2} mb={2}>
              <Typography variant="h5" className={classes.center}>
                {name}
              </Typography>
            </Box>
            <Box pt={1}>
              <Link to="/user/update" className={classes.decoration}>
                <div className={classes.center}>
                  <EditIcon />
                  <Box pl={1}>
                    <Typography>Update Account</Typography>
                  </Box>
                </div>
              </Link>
            </Box>
            <Box pt={5} mb={2} mt={3}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <PersonIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      User ID
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {_id}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <PhoneIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Phone
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {phone}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <LocationOnIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Address
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {address}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <CalendarTodayIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Birthday
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {moment(birthdate).format("L")}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <EmailIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Email
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {email}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <PersonIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Gender
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {gender}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <List className={classes.listBackground}>
                <div className={classes.flex}>
                  <LinkIcon className={classes.icon} />
                  <Box pl={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Facebook Username
                    </Typography>
                  </Box>
                </div>
                <Box pt={0.5} pb={0.5} mb={0.3}>
                  <Divider variant="middle" />
                </Box>
                <Typography variant="body2" className={classes.body}>
                  {!messenger ? "No messenger url" : messenger}
                </Typography>
              </List>
            </Box>
            <Box pt={1} mb={2}>
              <Divider />
            </Box>
            <Box pt={1} mb={2}>
              <Alert severity="error">
                <AlertTitle>Warning</AlertTitle>
                Once you delete your account, there's no getting it back make
                sure you want to do this.
              </Alert>
            </Box>
            <Box pt={1} mb={2}>
              <Button
                variant={"outlined"}
                fullWidth
                sx={{ color: "red", borderColor: "red" }}
                onClick={handleDeleteAccountOnClick}
              >
                DELETE ACCOUNT
              </Button>
            </Box>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: height,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <DeleteAccountDialog
          onConfirm={async () => {
            await onConfirmDeleteAccount()
          }}
        />
      </Container>
    </MuiThemeProvider>
  )
}

export default UserDetailsPage
