import { Form, Formik } from "formik"
import React from "react"
import useSparkleAlert from "../../../alert/useSparkleAlert"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Button from '@mui/material/Button'
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"


export function useDeleteAccountDialog() {
  const [show, setshow] = React.useState(false)

  const showDeleteAccountDialog = () => {
    setshow(true)
  }

  const closeDeleteAccountDialog = () => {
    setshow(false)
  }

  function DeleteAccountDialog({ onConfirm = () => {} }) {
    const { showSparkleAlert, SparkleAlert } = useSparkleAlert()

    return (
      <Dialog open={show} fullWidth>
        <DialogContent>
          <Stack spacing={2}>
            <Alert severity="error">
              <AlertTitle>Warning</AlertTitle>
              Once you delete your account, there's no getting it back make sure
              you want to do this. Please type DELETE PERMANENTLY
            </Alert>
            <Formik
              initialValues={{}}
              onSubmit={async (data) => {
                try {
                  await onConfirm()
                } catch (error) {
                  showSparkleAlert(`${error}`, "warning")
                }
              }}
              validate={(values) => {
                const errors = {}

                if (values?.confirmtext !== "DELETE PERMANENTLY") {
                  errors.confirmtext = "Please type DELETE PERMANENTLY"
                }

                return errors
              }}
            >
              {({ handleChange, isSubmitting, errors }) => {
                return (
                  <Form>
                    <Stack spacing={2}>
                      <TextField
                        name={"confirmtext"}
                        variant="outlined"
                        placeholder="Please type DELETE PERMANENTLY"
                        onChange={handleChange}
                        error={errors?.confirmtext}
                        helperText={errors?.confirmtext}
                      />
                      <SparkleAlert />
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          {isSubmitting ? "Loading..." : "Confirm"}
                        </Button>

                        <Button
                          disabled={isSubmitting}
                          variant="outlined"
                          fullWidth
                          onClick={closeDeleteAccountDialog}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                )
              }}
            </Formik>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }

  return { showDeleteAccountDialog, DeleteAccountDialog }
}
